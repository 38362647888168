import { post } from '@/utils/request'

//获取目录
export function getCoachList(data) {
    return post('/edu/Edu_Coach_Type/coachTypeList', data)
}
//添加教员类型
export function addCoachType(data) {
    return post('/edu/Edu_Coach_Type/addCoachType', data)
}
//教员类型回显
export function getInfo(data) {
    return post('/edu/Edu_Coach_Type/coachTypeInfo', data)
}

//教务类型编辑
export function editCoachType(data) {
    return post('/edu/Edu_Coach_Type/editCoach', data)
}
//教务类型删除
export function delCoachType(data) {
    return post('/edu/Edu_Coach_Type/delCoachType', data)
}



